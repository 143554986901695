<template>
  <div />
</template>

<script>
import { mapMutations } from 'vuex';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { toggleLoading, updateProgressBar, setCompanyId } from '@/plugins/vuex/mutationTypes';
import { accessToken } from '@/services/cookies';
import { SurveyStatus } from '@/utils/constants';

export default {
  name: 'SurveyTechPage',
  inject: ['Names'],

  data() {
    return {
      currentUserSurveySlug: '',
    };
  },

  computed: {
    surveySlug() {
      return this.$route.params.slug;
    },
    accessToken() {
      return accessToken.get();
    },
    isSurveyMultilingual() {
      return this.languages.length > 1;
    },
    isTestingFulfillment() {
      return this.$route.query.testing;
    },
    linkTypeOption() {
      return this.$route.query.type || 'auth';
    },
    isLinkTypeOpen() {
      return this.linkTypeOption === 'open';
    },
    isLinkTypeAuth() {
      return this.linkTypeOption === 'auth';
    },
  },

  created() {
    this.initSurvey();
  },

  methods: {
    ...mapMutations([toggleLoading, updateProgressBar, setCompanyId]),
    async initSurvey() {
      this[toggleLoading](true);

      try {
        const { data: welcomeResponse } = await apiService.surveyByUserSlugWelcomeGet(this.surveySlug);
        const {
          surveyName,
          surveyStatus,
          authorizationRequired,
          userSurveySlugSettings = ['authorization'],
          companyId,
        } = welcomeResponse;
        this[setCompanyId](companyId);
        const slugSettingOpen = userSurveySlugSettings.includes('anonymous')
          || userSurveySlugSettings.includes('shared');
        const slugSettingAuth = userSurveySlugSettings.includes('authorization');
        const isLinkTypeOk = (this.isLinkTypeOpen && slugSettingOpen)
          || (this.isLinkTypeAuth && slugSettingAuth);
        if (!isLinkTypeOk && this.$route.query.type) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_ANOTHER_ORGANIZATION,
            params: { slug: this.surveySlug },
          });
          return;
        }

        this[updateProgressBar]({ surveyName });
        if (surveyStatus === SurveyStatus.FINISHED) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_FINISHED,
            params: { slug: this.surveySlug },
          });
          return;
        }

        if (surveyStatus === SurveyStatus.PUBLISHED) {
          this.$router.push({
            name: this.Names.R_SURVEY_NOT_ACTIVE,
            params: { slug: this.surveySlug },
          });
          return;
        }

        if (authorizationRequired && !this.accessToken && !this.isLinkTypeOpen) {
          const url = new URL(`/?back=${window.location.href}`, process.env.VUE_APP_GATEKEEPER_ADDRESS);
          window.location.replace(url.href);
          return;
        }

        const options = {
          params: { link_type: this.$route.query.type ? this.linkTypeOption : undefined },
        };

        if (authorizationRequired && !this.isLinkTypeOpen) {
          options.headers = { Authorization: this.accessToken };
        }

        try {
          const { data } = await apiService.surveyByUserSlugCreate(this.surveySlug, options);
          this.currentUserSurveySlug = data?.slug;

          if (authorizationRequired && !data?.slug) {
            this.$router.push({
              name: this.Names.R_USER_SURVEY_ANOTHER_ORGANIZATION,
              params: { slug: this.surveySlug },
            });
            return;
          }
        } catch (err) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_ANOTHER_ORGANIZATION,
            params: { slug: this.surveySlug },
          });
          return;
        }

        const { data: languages } = await apiService.surveyLanguagesGet(this.currentUserSurveySlug);
        if (languages.length > 1) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_LANGUAGES,
            params: { slug: this.currentUserSurveySlug },
          });
          return;
        }

        try {
          const { data } = await apiService.surveySessionWelcomeGet(this.currentUserSurveySlug);
          if (data.orgstructureRequired && data.orgstructurePageOrder === 0) {
            const query = this.isTestingFulfillment ? { testing: true } : {};
            this.$router.push({
              name: this.Names.R_SURVEY_ORGSTRUCTURE,
              params: { slug: this.currentUserSurveySlug },
              query,
            });
            return;
          }
        } catch (err) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_ANOTHER_ORGANIZATION,
            params: { slug: this.currentUserSurveySlug },
          });
          return;
        }

        this.$router.push({
          name: this.Names.R_USER_SURVEY_PAGE,
          params: { slug: this.currentUserSurveySlug },
          query: this.isTestingFulfillment ? { testing: true } : {},
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
  },
};
</script>
